import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import Video from "../components/video"
import { Container } from "react-bootstrap";

class VideoPost extends Component {
  render() {
    const videopost = this.props.data.youtubeVideo

    return (
      <>
        <Layout>
          <Container>
            <h1>{videopost.title}</h1>

            <Video
              videoSrURL={"https://www.youtube.com/embed/" + videopost.videoId}
              videoTitle={videopost.title}
            />
            <div class="script">{videopost.publishedAt}</div>
            <div>{videopost.description}</div>
          </Container>
        </Layout>
      </>
    )
  }
}

VideoPost.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default VideoPost

export const pageQuery = graphql`
  query($id: String!) {
    youtubeVideo(id: { eq: $id }) {
      title
      description
      videoId
      publishedAt(formatString: "MMMM DD, YYYY")
    }
  }
`